import './index.sass';
import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
window.UIkit = UIkit;


// loads the Icon plugin
UIkit.use(Icons);

// components can be called from the imported UIkit reference
//UIkit.notification('Hello world.');
$( document ).ready(function() {
  const text = 'Push me';
  const satisfaction = `${text}, and then just touch me`;

  (() =>console.log(satisfaction))();
});


